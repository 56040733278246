import React, { useEffect, useState } from "react";
import { Card, Grid } from "@material-ui/core";
import { NavigateBefore, NavigateNext } from "@material-ui/icons";
import "./cardNews.scss";

function CardNews() {
  const slides = [
    {
      link: "https://servicos.fiat.com.br/agendamento.html",
      source:
        "https://ceabs-sites.s3.amazonaws.com/CPS/myuconnect/banner01.png",
    },
    {
      link: "https://agendamentoonline.peugeot.com.br/pt-BR/online-booking-peugeot",
      source:
        "https://ceabs-sites.s3.amazonaws.com/CPS/myuconnect/banner02.jpeg",
    },
    {
      link: "https://agendamentoonline.peugeot.com.br/pt-BR/online-booking-peugeot",
      source:
        "https://ceabs-sites.s3.amazonaws.com/CPS/myuconnect/banner03.png",
    },
    {
      link: "https://www.citroen.com.br/meu-citroen/citroen-assistance.html",
      source:
        "https://ceabs-sites.s3.amazonaws.com/CPS/myuconnect/banner04.png",
    },
  ];
  const [currentSlide, setCurrentSlide] = useState(0);

  const goToSlide = (index) => {
    setCurrentSlide(index);
  };

  const nextSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === slides.length - 1 ? 0 : prevSlide + 1
    );
  };

  const prevSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === 0 ? slides.length - 1 : prevSlide - 1
    );
  };

  useEffect(() => {
    const interval = setInterval(() => nextSlide(), 10000); // Troca de slide a cada 10 segundos

    return () => clearInterval(interval);
  }, [slides.length]);

  return (
    <Grid item xs={12}>
      <Card className="card-news-2" style={{ display: "flex" }}>
        <div className="text-news-2">
          <div
            style={{
              width: "100%",
              height: "100%",
              objectFit: "contain",
              textAlign: "center",
            }}
          >
            <h2>
              {" "}
              Uma solução completa, simples e integrada de telemetria e
              rastreamento para controle e gerenciamento da sua frota,
              independente do tamanho dela.
            </h2>
          </div>
        </div>
      </Card>
      <Card className="card-news-2" style={{ display: "flex" }}>
        <a
          href={slides[currentSlide].link}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={slides[currentSlide].source}
            alt="{title}"
            className="img-news-4"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "contain",
              transition: "opacity 1s ease-in-out",
              opacity: "1",
            }}
          />
        </a>
        <div className="next-button" onClick={nextSlide}>
          <NavigateNext color="white" />
        </div>
        <div className="prev-button" onClick={prevSlide}>
          <NavigateBefore color="white" />
        </div>
        <div className="dots-container">
          {slides.map((dot, index) => (
            <div
              onClick={() => goToSlide(index)}
              className={`dot ${dot === slides[currentSlide] ? "active" : ""}`}
            />
          ))}
        </div>
      </Card>
    </Grid>
  );
}

export default CardNews;
